import ApiService from '@/shared/services/api.service';
import { storeAsync } from "@/app-module";
import Countries from '@/shared/utils/countries';

export class CommonService {

    static async retrieveLocalities(params, url) {
        if (!params?.parent_id) {
            let countyId = '';
            countyId = await storeAsync().getters['shared/defaultCountyId'];
            if (!countyId) {
                // If defaultCountyId is not received because fetchSettings actions is trigger
                // after the state in updated in getter  (there is a case on test env), recall the fetchSettings action to get the defaultCountyId
                await storeAsync().dispatch('shared/fetchSettings');
                countyId = await storeAsync().getters['shared/defaultCountyId'];
            }
            params.parent_id = countyId;
        }

        const response = await ApiService.query(url, { params });

        if (params?.search) {
            const result = response.data.filter(item => item.name.toLowerCase().startsWith(params.search));
            return result;
        }

        return response.data;
    }

    static async localityListAutocomplete(params = {}) {
        return CommonService.retrieveLocalities(params, 'localities');
    }

    static async territorialAdministrativeUnitsListAutocomplete(params = {}) {
        return CommonService.retrieveLocalities(params, 'territorial-administrative-units');
    }

    static async territorialAdministrativeUnitsAssignedListAutocomplete(params = {}) {
        return CommonService.retrieveLocalities(params, 'territorial-unit-allocations/uats');
    }

    static getCountries() {
        return new Promise((resolve) => resolve(Countries));
    }

    static async getVillages(params = {}) {
        if (!params?.parent_id) {
            return [];
        }
        const response = await ApiService.query('villages', { params });

        if (params?.search) {
            const result = response.data.filter(item => item.name.toLowerCase().startsWith(params.search));
            return result;
        }

        return response.data;
    }
}
