<template>
  <st-modal
    ref="modal"
    hide-header-delimiter
    hide-footer-delimiter
    no-close-on-backdrop
    no-close-on-esc
    hideable
    hide-header-close
    size="xl"
    :id="modalId"
    custom-class="form-table"
  >
    <template #header>
        <div class="d-flex justify-content-between w-100">
            <p class="font-size-h6 font-weight-bold mb-0 pb-0">
                {{ layerName }}
            </p>
            <st-button
                variant="secondary"
                class="font-size-sm"
                :callback="doEdit"
                v-if="!editMode && !isSaveActive && !isEditLayerActive && isLayerContainer"
            >
                <i class="fa fa-edit"></i>
                <span>{{ $t('ATLAS.BUTTONS.UPDATE_CONTAINER') }}</span>
            </st-button>
            <st-button
                v-if="editMode"
                variant="link"
                class="font-size-h6"
                :callback="()=> editMode = false"
            >
                <i class="fas fa-arrow-left text-primary"></i>
                <span>{{ $t('GENERAL.PREV') }}</span>
            </st-button>
        </div>
    </template>
    <template #body>
        <container-layer-form
            v-if="editMode"
            :id="modalId"
            :editMode="editMode"
            :selectedLayer="layer"
            ref="container_layer_form"
        />
       <layer-edit-modal-form
            v-if="!editMode"
            ref="layer-edit-modal-form"
            :isVisible="isVisible"
            :isSaveActive.sync="isSaveActive"
            :isEditLayerActive.sync="isEditLayerActive"
        />
    </template>
    <template #footer>
        <div class="d-flex justify-content-between w-100">
            <st-button
                size="large"
                variant="link"
                :callback="hide"
            >
                {{ $t("GENERAL.BUTTON.CANCEL") }}
            </st-button>
            <st-button
                v-if="isSaveActive || editMode || isEditLayerActive"
                size="large"
                variant="primary"
                :callback="doSave"
            >
                <span>{{ $t("GENERAL.BUTTON.SAVE") }}</span>
            </st-button>
        </div>
    </template>
  </st-modal>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { generateRandomId } from "@/core/helpers/globalMethods";
import LayerEditModalForm from "@/modules/atlas/components/layer-manager/LayerEditModalForm";
import ContainerLayerForm from "@/modules/atlas/components/layer-manager/ContainerLayerForm";
export default {
  inheritAttrs: false,
  name: "LayerEditModal",
  components: { LayerEditModalForm, ContainerLayerForm},
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalId: `st-modal-layer-edit-${generateRandomId()}`,
      editMode: false,
      isSaveActive: false,
      isEditLayerActive: false
    };
  },
  computed: {
    ...mapGetters({
        layer: 'atlas/layer-manager/editingLayer',
    }),
    layerName() {
        return this.layer?.name || '';
    },
    isLayerContainer() {
        return this.layer?.layer_type === 'container'
    }
  },
  methods: {
    ...mapActions({
        hideEditModal: "atlas/layer-manager/hideEditModal",
        updateLayerContainer: 'atlas/layer-manager/updateLayerContainer',
        getLayers: "atlas/layer-manager/getLayers",
    }),
    show() {
        this.$refs["modal"].show();
    },
    hide() {
        this.$refs["modal"].hide();
        this.editMode = false;
        this.isSaveActive = false;
        this.isEditLayerActive = false;
        this.hideEditModal();
    },
    async doSave() {
        if (this.editMode) {
            const formRef = this.$refs.container_layer_form;
            const formFields = formRef.fields;
            const validate = await formRef.fv.validate();
            if (validate === "Valid") {
                let payload = formRef.model;
                payload.layer_name = 'container';
                if (!payload.owner_org_name) {
                    payload.owner_org_name = formFields.owner_org_id.options.find(f => f.value === payload.owner_org_id)?.text;
                }
                // Remove unnecessary/undefined props
                Object.keys(payload).forEach(key => {
                    if (!payload[key]) delete payload[key];
                });

                this.updateLayerContainer({ layer: this.layer, payload })
                .then(() => {
                    this.getLayers();
                    this.$notify({
                        type: 'success',
                        message: this.$t('GENERAL.UPDATED_SUCCESS'),
                    });
                })
            }
        } else {
            this.$refs['layer-edit-modal-form'].doSubmit();
        }
    },
    doEdit() {
        this.editMode = true;
    }
  },
};
</script>

<style lang="scss">
  .form-table {
    .data-table {
      padding: 0;
      &:first-of-type {
        border-right: 1px solid #E7EDF3;
        box-shadow: 4px 0px 4px -4px rgb(0 0 0 / 16%);
      }
    }
  }
    .fade-enter-active {
        transition: all .3s ;
    }
    .fade-leave-active {
        opacity: 0;
    }
    .fade-enter  {
        transform: translateX(-5%);
    }
    .fade-leave-to {
        opacity: 0;
    }
</style>

