<template>
    <div :class="['layer-item', layerTypeClass, layerSelected == layer.id ? 'atlas-layer-active' :'' ]">
        <div class="layer-name"
            :class="{'cursor-pointer': layer.layer_type === 'simple'}"
            @click="openLayerDetails(layer.id)"
        >
            <i :class="[layerIconClass, 'layer-icon', layerSelected == layer.id ? 'atlas-layer-active' :'']"></i>
            <span>{{ layer.name }}</span>
        </div>
        <div v-if="layer.layer_type === 'simple' && group === 'private'" class="align-middle ml-4">
            <span :class="`label label-md label-inline label-light-${layer.type}`">
                {{ $t(`ATLAS.PUBLISH_STATUS.${layer.publish_status.toUpperCase()}`) }}
            </span>
        </div>
        <div class="layer-actions">
            <b-form-checkbox
                switch
                :checked="isLayerSelected"
                @change="toggleLayer"
                size="lg"
                v-if="hasLayers"
            ></b-form-checkbox>
            <b-dropdown class="button-more-action" variant="link" no-caret menu-class="button-more-action-menu">
                <template #button-content>
                    <i class="fas fa-ellipsis-h"></i>
                </template>
                <!-- TODO: uncomment once functionalities are implemented -->
                <!-- <b-dropdown-item-button>{{ $t('ATLAS.LAYER_MANAGER.MORE_ACTION.SHARE') }}</b-dropdown-item-button> -->
                <b-dropdown-item-button :disabled="!canPublish" @click="publishLayer">{{ $t('ATLAS.LAYER_MANAGER.MORE_ACTION.PUBLISH') }}</b-dropdown-item-button>
                <b-dropdown-item-button @click="editLayer">{{ $t('ATLAS.LAYER_MANAGER.MORE_ACTION.EDIT') }}</b-dropdown-item-button>
                <!-- <b-dropdown-item-button>{{ $t('ATLAS.LAYER_MANAGER.MORE_ACTION.EXPORT') }}</b-dropdown-item-button> -->
                <b-dropdown-item-button @click="deleteLayer">{{ $t('ATLAS.LAYER_MANAGER.MORE_ACTION.DELETE') }}</b-dropdown-item-button>
            </b-dropdown>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

const LAYER_TYPE = {
    simple: 'simple',
    container: 'container'
};

const LAYER_TYPE_CLASS = {
    simple: 'simple-layer',
    container: 'container-layer'
};

const LAYER_TYPE_ICON_CLASS = {
    simple: 'fas fa-layer-group',
    container: 'fas fa-folder',
    containerOpen: 'fas fa-folder-open'
};

export default {
    name: "LayerItem",
    props: {
        layer: {
            type: Object,
            required: true,
        },
        isContainerOpen: {
            type: Object,
        },
        layerSelected:{
            type: String,
            default: '0'
        },
        group:{
            type: String,
            required: true,
        },
        container:{
            type: Object,
            default: () => ({ }),
        }
    },
    data() {
        return {
            layerType: this.layer.layer_type,
            containerOpen: false,
            layerTypeClass: LAYER_TYPE_CLASS[this.layer.layer_type],
            type: 'primary'
        }
    },
    computed: {
        ...mapGetters({
            getLayerVisibilityById: 'atlas/layer-manager/getLayerVisibilityById',
        }),
        layerIconClass() {
            if (this.containerOpen) {
                return LAYER_TYPE_ICON_CLASS.containerOpen;
            }
            return LAYER_TYPE_ICON_CLASS[this.layerType];
        },
        isLayerSelected() {
            return this.getLayerVisibilityById(this.layer.gis_layer_id);
        },
        hasLayers() {
            if (this.layer.layer_type === 'simple') return true;
            return !!this.layer.components.length;
        },
        canPublish() {
            return (this.layer.layer_type === 'simple' &&
                    !this.layer?.container_id &&
                    this.layer?.status &&
                    !this.layer?.is_published
                );
        },
    },
    methods: {
        ...mapActions({
            showLayer: 'atlas/layer-manager/showLayer',
            hideLayer: 'atlas/layer-manager/hideLayer',
            showAllLayers: 'atlas/layer-manager/showAllLayers',
            hideAllLayers: 'atlas/layer-manager/hideAllLayers',
            selectLayer: 'atlas/layer-manager/selectLayer',
            showPublishModal: 'atlas/layer-manager/showPublishModal',
            showEditModal: 'atlas/layer-manager/showEditModal',
            doDeleteLayer: 'atlas/layer-manager/deleteLayer',
        }),
        openLayerDetails(layerId) {
            this.$emit('update:layerSelected', layerId);
            if (this.layerType === LAYER_TYPE.container) {
                this.containerOpen = !this.containerOpen;
                this.$emit('update:isContainerOpen', {container: this.containerOpen, id: layerId});
            } else {
                this.selectLayer({...this.layer, group: this.group, container_name: this.container?.name ?? ''});
            }
        },
        toggleLayer(checked) {
            if (this.layer.layer_type === LAYER_TYPE.container) {
                this[checked ? 'showAllLayers': 'hideAllLayers'](this.layer.components);
            } else {
                this[checked ? 'showLayer' : 'hideLayer'](this.layer);
            }
        },
        publishLayer() {
            this.showPublishModal(this.layer);
        },
        editLayer() {
            this.showEditModal(this.layer);
        },
        deleteLayer() {
            this.$alert({
                type: 'error',
                    title: this.$t('ATLAS.LAYER_MANAGER.DELETE_MODAL_TITLE'),
                    text: this.$t('ATLAS.LAYER_MANAGER.DELETE_MODAL_SUBTITLE'),
                    confirmButtonText: this.$t('GENERAL.YES'),
                    cancelButtonText: this.$t('GENERAL.NO'),
                    hasConfirmation: true,
                    confirmCallback: () => {
                       this.doDeleteLayer(this.layer);
                    }
                });
        },
    }
}
</script>

<style lang="scss">
    .atlas-layer-active {
        color:#286AE4;
    }
</style>
