<template>
    <div class="layer-container">
        <layer-group id="atlas" :layers="atlasLayers"></layer-group>
        <layer-group id="private" :layers="simpleLayers"></layer-group>
    </div>
</template>
<script>
import LayerGroup from "@/modules/atlas/components/layer-manager/LayerGroup";
import { mapGetters } from 'vuex';
const STATUS = {
    new: 'info',
    pending: 'warning',
    completed: 'success',
    error: 'danger',
}
export default {
  name: "LayerContainer",
  components: {
      LayerGroup,
  },
  props: {
      layers: {
          type: Array,
          default: () => [],
      }
  },
  computed: {
    ...mapGetters({
        layerCreationStatus: 'atlas/layer-manager/layerCreationStatus',
        publishedLayerQueue: 'atlas/layer-manager/publishedLayerQueue',
        simpleLayers: 'atlas/layer-manager/simpleLayers',
        publishedLayerQueue: 'atlas/layer-manager/publishedLayerQueue',
    }),
    atlasLayers() {
        return this.layers.filter((el) => el.layer_type === 'container') || [];
    },
  },
  watch: {
    layerCreationStatus(newValue) {
        if(newValue) {
            const inPublishLayer = this.publishedLayerQueue.find((item) => item.layer.id === newValue.id);
            const updatedLayer = this.simpleLayers[inPublishLayer.listIndex];
            const type = STATUS[newValue.status];
            this.$set(this.simpleLayers, inPublishLayer.listIndex, {...updatedLayer, publish_status:newValue.status, type});
        }
    }
  },
};
</script>
<style lang="scss">
@import './src/assets/sass/pages/atlas/atlas';
</style>
