<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-6">
        <label> {{ fields.name.label }} * </label>
        <div class="form-group">
          <st-input-text
            v-model="model[fields.name.name]"
            :ref="fields.name.name"
            :name="fields.name.name"
          />
        </div>
      </div>
      <div class="col-12 col-md-6">
        <label>
          {{ fields.ru_scope.label }} *
        </label>
        <div class="form-group">
          <st-select
            v-model="model[fields.ru_scope.name]"
            :ref="fields.ru_scope.name"
            :field="fields.ru_scope"
            :formValidation="fv"
          ></st-select>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <label>
          {{ fields.uat_id.label }} *
        </label>
        <div class="form-group">
          <st-autocomplete
                v-model="model[fields.uat_id.name]"
                :ref="fields.uat_id.name"
                :name="fields.uat_id.name"
                :options="fields.uat_id.options"
                :fields="fields"
                :model="model"
                :formValidation="fv"
            >
            </st-autocomplete>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <label> {{ fields.owner_org_id.label }} * </label>
        <div class="form-group">
          <st-select
            v-model="model[fields.owner_org_id.name]"
            :ref="fields.owner_org_id.name"
            :field="fields.owner_org_id"
            :formValidation="fv"
          ></st-select>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { FormSchema } from "@/shared/form/form-schema";
import { AtlasLayerModel } from "@/modules/atlas/models/atlas-layer-model";
import { createFormValidation } from "@/shared/utils/create-form-validation";

const { fields } = AtlasLayerModel;

const formSchema = new FormSchema([
  fields.name,
  fields.owner_org_id,
  fields.ru_scope,
  fields.uat_id,
]);

export default {
  name: "SimpleLayerForm",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      rules: formSchema.rules(),
      model: null,
      fields,
      fv: null,
    };
  },
  created() {
    this.model = formSchema.initialValues();
    this.model.access_level = 'private';
  },
  mounted() {
    this.fv = createFormValidation(this.id, this.rules);
  },
};
</script>
<style lang="">
</style>
