<template>
  <st-modal
    ref="modal"
    hide-header-delimiter
    no-close-on-backdrop
    no-close-on-esc
    hide-footer
    hideable
    hide-header-close
    :title="$t('ATLAS.CREATE_LAYER_MODAL.HEADER')"
    :hideHeaderClose="isLoading"
    customClass="form-modal"
    :id="modalId"
  >
    <template #body>
      <form
        class="form"
        novalidate="novalidate"
        id="st_modal_create_layer_form"
      >
        <div class="row">
          <div class="col-12 col-md-6">
            <label>
              {{ fields.layer_type.label }} *
            </label>
            <div class="form-group">
              <b-form-radio-group
                size="lg"
                v-model="model[fields.layer_type.name]"
                :options="fields.layer_type.options"
                :name="fields.layer_type.name"
                @change="changeLayerType"
              ></b-form-radio-group>
            </div>
          </div>
        </div>
        <container-layer-form
          v-if="layerType === 'container'"
          :id="modalId"
          ref="container_layer_form"/>
        <simple-layer-form
          v-else :id="modalId"
          ref="simple_layer_form"
          />
      </form>
      <div class="pt-6">
        <div class="d-flex justify-content-between">
          <st-button
            size="large"
            variant="link"
            :callback="hide"
            :disabled="isLoading"
          >
            {{ $t("GENERAL.BUTTON.CANCEL") }}
          </st-button>
          <st-button
            size="large"
            variant="primary"
            :callback="doSubmit"
            :spinner="isLoading"
          >
            <span>{{ $t("GENERAL.BUTTON.ADD") }}</span>
          </st-button>
        </div>
      </div>
    </template>
  </st-modal>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { generateRandomId } from "@/core/helpers/globalMethods";
import { AtlasLayerModel } from "@/modules/atlas/models/atlas-layer-model";

import ContainerLayerForm from "@/modules/atlas/components/layer-manager/ContainerLayerForm";
import SimpleLayerForm from "@/modules/atlas/components/layer-manager/SimpleLayerForm";

const { fields } = AtlasLayerModel;
const DEFAULT_LAYER_TYPE = 'simple';

export default {
  inheritAttrs: false,
  name: "LayerCreationFormModal",
  props: {
    file: null,
  },
  components: {
    ContainerLayerForm,
    SimpleLayerForm,
  },
  data() {
    return {
      modalId: `st-modal-layer-creation-${generateRandomId()}`,
      polling: null,
      fields,
      model: {},
      layerType: DEFAULT_LAYER_TYPE,
      errorMesage: ''
    };
  },
  computed: {
    ...mapGetters({
      loading: "shared/loading",
      layers: 'atlas/layer-manager/layers',
    }),
    isLoading() {
      return this.loading["atlas-layer-manager/create"];
    },
  },
  methods: {
    ...mapActions({
      doUpload: "atlas/layer-manager/upload",
      createLayer: "atlas/layer-manager/createLayer",
      updateLayer: "atlas/layer-manager/updateLayer",
      getLayerCreationStatus: "atlas/layer-manager/getLayerCreationStatus",
      addLayerToList: "atlas/layer-manager/addLayer",
    }),
    changeLayerType(value) {
      this.layerType = value;
    },
    async doSubmit() {
      let formRef = null;
      if (this.layerType === 'container') {
        formRef = this.$refs.container_layer_form;
      } else {
        formRef = this.$refs.simple_layer_form;
      }
      // Validate ru_scope: new container must not have same ru_scope with existing one
      if (this.model.layer_type === 'container') {
            const layeModel = formRef.model;
            const fv = formRef.fv;
            fv.resetField('ru_scope');
            fv.addField('ru_scope', {
                validators: {
                    callback: {
                        message: this.$t('ATLAS.CREATE_LAYER_MODAL.ERROR'),
                        callback: (input)=> {
                            const ruScopeExist = this.layers.some((layer) => {
                                // For 'other' RU scope new layer of container type can be added
                                if (layeModel.ru_scope === 'other') return false;
                                return layer.layer_type === "container" && layer.ru_scope === layeModel.ru_scope
                            });
                            return !ruScopeExist
                        },
                    },
                }
            });
            fv.revalidateField('ru_scope');
        }
      const validate = await formRef.fv.validate();
      if (validate === "Valid") {
        this.doCreateLayer(formRef);
      }
    },
    async doCreateLayer(formRef) {
      const payload = formRef.model;
      payload.layer_type = this.model.layer_type;
      if (this.model.layer_type === 'container') {
        payload.layer_name = formRef.model.layer_name;
      }
      this.isUploading = true;
      this.$emit("uploadStart");
      try {
        const { data, uploadAccessToken } = await this.createLayer(payload);
        const { id } = data;
        const formData = new FormData();
        formData.append("files", this.file);
        const fileData = await this.doUpload({
          files: formData,
          uploadAccessToken,
        });
        const values = {
          source_file_id: fileData[0].id, // Only one file uploaded
        };
        await this.updateLayer({ id, values });
        this.polling = setInterval(() => {
          this.checkLayerCreationStatus(id);
        }, 3000);
      } catch (error) {
        this.isUploading = false;
        this.$emit("uploadEnd", this.uploadStatus);
        this.uploadStatus = "error";
      }
      this.hide();
    },
    async checkLayerCreationStatus(id) {
      try {
        const { status, ...layer } = await this.getLayerCreationStatus(id);
        this.uploadStatus = status;
        if (status === "completed") {
          this.addLayerToList(layer);
        }
      } catch (error) {
        clearInterval(this.polling);
      } finally {
        if (this.uploadStatus !== "new" && this.uploadStatus !== "pending") {
          this.isUploading = false;
          this.$emit("uploadEnd", this.uploadStatus);
          clearInterval(this.polling);
        }
      }
    },
    show() {
      this.initValues();
      this.$refs["modal"].show();
    },
    hide() {
      this.$refs["modal"].hide();
    },
    initValues() {
      this.layerType = DEFAULT_LAYER_TYPE,
      this.model = {
        layer_type: this.layerType,
      };
    },
  },
  created() {
    this.model.layer_type = this.layerType;
  },
  beforeDestroy() {
    if (this.polling) {
      clearInterval(this.polling);
    }
  },
};
</script>

