<template>
  <div>
    <p v-if="editMode" class="font-size-h6 mb-6">  {{ $t('ATLAS.LAYER_MANAGER.UPDATE_CONTAINER_TITLE') }} </p>
    <div class="row">
      <div class="col-12 col-md-6">
        <label> {{ $t('ATLAS.FIELDS.CONTAINER_NAME') }} * </label>
        <div class="form-group">
          <st-input-text
            v-model="model[fields.name.name]"
            :ref="fields.name.name"
            :name="fields.name.name"
          />
        </div>
      </div>
      <div class="col-12 col-md-6">
        <label> {{ fields.access_level.label }} * </label>
        <div class="form-group">
          <st-select
            v-model="model[fields.access_level.name]"
            :ref="fields.access_level.name"
            :field="fields.access_level"
            :formValidation="fv"
          ></st-select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <label>
          {{ fields.ownership_type.label }} *
        </label>
        <div class="form-group">
          <st-select
            v-model="model[fields.ownership_type.name]"
            :ref="fields.ownership_type.name"
            :field="fields.ownership_type"
            :formValidation="fv"
          ></st-select>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <label> {{ fields.owner_org_id.label }} * </label>
        <div class="form-group">
          <st-select
            v-model="model[fields.owner_org_id.name]"
            :ref="fields.owner_org_id.name"
            :field="fields.owner_org_id"
            :formValidation="fv"
          ></st-select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <label>
          {{ fields.ru_scope.label }} *
        </label>
        <div class="form-group">
          <st-select
            v-model="model[fields.ru_scope.name]"
            :ref="fields.ru_scope.name"
            :field="fields.ru_scope"
            :formValidation="fv"
          ></st-select>
        </div>
      </div>
    </div>
    <div v-if="!editMode">
        <hr/>
        <div class="row">
        <div class="col-12 col-md-6">
            <label>
            {{ fields.layer_name.label }} *
            </label>
            <div class="form-group">
            <st-input-text
                v-model="model[fields.layer_name.name]"
                :ref="fields.layer_name.name"
                :name="fields.layer_name.name"
            />
            </div>
        </div>
        <div class="col-12 col-md-6">
            <label>
            {{ fields.uat_id.label }} *
            </label>
            <div class="form-group">
            <st-autocomplete
                v-model="model[fields.uat_id.name]"
                :ref="fields.uat_id.name"
                :name="fields.uat_id.name"
                :options="fields.uat_id.options"
                :fields="fields"
                :model="model"
                :formValidation="fv"
            >
            </st-autocomplete>
            </div>
        </div>
        </div>
        <div class="row">
            <div class="col-12">
            <div class="form-group">
                <b-form-checkbox
                v-model="model[fields.use_as_template.name]"
                :ref="fields.use_as_template.name"
                :name="fields.use_as_template.name"
                size="lg"
                >
                {{ fields.use_as_template.label }}
                </b-form-checkbox>
            </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import { FormSchema } from "@/shared/form/form-schema";
import { AtlasLayerModel } from "@/modules/atlas/models/atlas-layer-model";
import { createFormValidation } from "@/shared/utils/create-form-validation";

const { fields } = AtlasLayerModel;

const formSchema = new FormSchema([
  fields.name,
  fields.access_level,
  fields.ownership_type,
  fields.owner_org_id,
  fields.layer_name,
  fields.ru_scope,
  fields.uat_id,
  fields.use_as_template,
]);

export default {
  name: "ContainerLayerForm",
  props: {
      id: {
          type: String,
          required: true,
      },
      selectedLayer: {
          type: Object,
          default: () => ({}),
      },
      editMode: {
           type: Boolean,
           default: false,
      }
  },
  data() {
    return {
      rules: formSchema.rules(),
      model: null,
      fields,
      fv: null,
    };
  },
  watch: {
    'model.uat_id': {
        deep: true,
        immediate: true,
        handler(uat) {
            if (uat) {
                this.model.uat_name = this.fields.uat_id.options.find(f => f.value === uat)?.text;
            }
        },
    },
    'model.owner_org_id': {
        deep: true,
        immediate: true,
        handler(org) {
            if (org) {
                this.model.owner_org_name = this.fields.owner_org_id.options.find(f => f.value === org)?.text;
            }
        },
    },
  },
  created() {
    this.model = formSchema.initialValues(this.selectedLayer);
  },
  mounted() {
    this.fv = createFormValidation(this.id, this.rules);
  },
};
</script>
<style lang="">
</style>
