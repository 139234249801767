<template lang="">
    <div class="layer-manager">
        <portal to="atlas-sidebar-title">
          <span>{{ $t('ATLAS.LAYER_MANAGER.TITLE') }}</span>
        </portal>
        <div class="layer-manager__search">
            <b-input-group>
              <b-input-group-prepend is-text>
                <i class="fas fa-search"></i>
              </b-input-group-prepend>
              <b-form-input
                type="search"
                v-model="filter"
                :placeholder="$t('ATLAS.LAYER_MANAGER.SEARCH_LAYER')">
                </b-form-input>
            </b-input-group>
        </div>
        <layer-container :layers="filteredLayers" v-if="isLoaded"/>
        <layer-uploader/>
        <layer-publish-form-modal ref="layerPublishModal"/>
        <layer-edit-modal ref="layerEditModal" />
        <compare-layers-modal ref="compareLayersModal"/>
    </div>
</template>
<script>
import LayerContainer from '@/modules/atlas/components/layer-manager/LayerContainer';
import LayerUploader from '@/modules/atlas/components/layer-manager/LayerUploader';
import LayerPublishFormModal from '@/modules/atlas/components/layer-manager/LayerPublishFormModal';
import LayerEditModal from '@/modules/atlas/components/layer-manager/LayerEditModal';
import CompareLayersModal from "@/modules/atlas/components/layer-manager/CompareLayersModal";
import { mapGetters, mapActions } from 'vuex';

export default {
  name: "LayerManager",
  components: {
    LayerContainer,
    LayerUploader,
    LayerPublishFormModal,
    LayerEditModal,
    CompareLayersModal ,
  },
  data() {
    return {
      filter: '',
      isLoaded: false,
    }
  },
  watch: {
    isLayerPublishModalVisible(newValue) {
      if (newValue) {
        this.$refs.layerPublishModal.show();
      } else {
        this.$refs.layerPublishModal.hide();
      }
    },
    isLayerEditModalVisible(newValue) {
      if (newValue) {
        this.$refs.layerEditModal.show();
      } else {
        this.$refs.layerEditModal.hide();
      }
    },
    isCompareLayersModalVisible(newValue) {
      if (newValue) {
        this.$refs.compareLayersModal.show();
      } else {
        this.$refs.compareLayersModal.hide();
      }
    },
  },
  computed: {
    ...mapGetters({
        layers: 'atlas/layer-manager/layers',
        isLayerPublishModalVisible: 'atlas/layer-manager/isLayerPublishModalVisible',
        isLayerEditModalVisible: 'atlas/layer-manager/isLayerEditModalVisible',
        isCompareLayersModalVisible: 'atlas/layer-manager/isCompareLayersModalVisible',
    }),
    filteredLayers() {
      return this.filter ? this.layers.filter((el) => el.name.toLowerCase().startsWith(this.filter.toLowerCase())) : this.layers;
    },
  },
  methods: {
    ...mapActions({
      getLayers: "atlas/layer-manager/getLayers",
      saveSelectedMenu: 'atlas/sidebar/saveSelectedMenu',
    }),
  },
  async created() {
    this.saveSelectedMenu('layer-manager');
    await this.getLayers();
    this.isLoaded = true;
  },
};
</script>
<style scoped lang="scss">
.layer-manager {
    display: flex;
    flex-direction: column;
    padding-bottom: 2.5rem;
    height: calc(100% - 4rem);
    &__header {
        height: 3.6rem;
        font-size: 1.125rem;
        font-weight: 500;
        padding: 0 2rem;
    }
    .input-group {
        border-color: #C7CFD6;
        border-width: 1px 0;
        border-style: solid;
      .input-group-text {
        background-color: white;
        border: 0;
        color: #888C9F;
        font-size: 1.5rem;
      }
      .form-control {
        border: 0;
        font-size: 1.35rem;
        padding: 1rem 0.75re 1rem 0;
        height: 55px;
        color: #888C9F;
        border-radius: 0;
      }
    }
}
</style>
