<template lang="">
    <div class="box-wrapper">
        <div class="box"
          @dragover="dragover"
          @dragleave="dragleave"
          @drop="drop">
            <div class="box__input" v-show="!uploadStatus">
                <div class="box__icon mb-4">
                    <i class="fas fa-file-upload" @click="browseFile"></i>
                </div>
                <b-form-file
                    v-show="false"
                    @change="onChange"
                    id="files"
                    class="box__file"
                    accept=".zip, .shp"
                    ref="upload_documents"
                ></b-form-file>
                <div class="box__label">
                    <div>{{ $t('ATLAS.LAYER_MANAGER.UPLOAD_LAYER.TITLE') }}</div>
                </div>
            </div>
            <div class="box__uploading" v-if="isUploading">{{ $t('ATLAS.LAYER_MANAGER.UPLOADING') }}</div>
            <div class="box__success" v-if="uploadStatus === 'completed'">{{ $t('ATLAS.LAYER_MANAGER.UPLOAD_DONE') }} <span class="box__reset" @click="browseFile">{{ $t('ATLAS.LAYER_MANAGER.UPLOAD_MORE') }}</span></div>
            <div class="box__error" v-if="uploadStatus === 'error'">{{ $t('ATLAS.LAYER_MANAGER.UPLOAD_ERROR') }} <div v-if="errorMessage">{{ errorMessage }}</div><span class="box__reset" @click="browseFile">{{ $t('ATLAS.LAYER_MANAGER.UPLOAD_TRY') }}</span></div>
        </div>
        <layer-creation-form-modal
          ref="layerCreationModal"
          :file="file"
          @uploadStart="uploadStart"
          @uploadEnd="uploadEnd"
          />
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import LayerCreationFormModal from '@/modules/atlas/components/layer-manager/LayerCreationFormModal';

export default {
  name: "LayerUploader",
  components: {
    LayerCreationFormModal,
  },
  data() {
    return {
      errorMessage: "",
      file: null,
      polling: null,
      uploadStatus: "",
      isUploading: false,
      files: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    ...mapActions({
      selectLayer: "atlas/layer-manager/selectLayer",
      doUpload: "atlas/layer-manager/upload",
      createLayer: "atlas/layer-manager/createLayer",
      updateLayer: "atlas/layer-manager/updateLayer",
      getLayerCreationStatus: "atlas/layer-manager/getLayerCreationStatus",
    }),
    browseFile() {
      this.files = null;
      this.file = null;
      this.uploadStatus = '';
      this.$nextTick(() => {
        this.$refs.upload_documents.$el.childNodes[0].click();
      });
    },
    onChange(event) {
      this.uploadStatus = '';
      this.files = event.target.files;
      this.uploadFile();
      this.$refs.upload_documents.reset();
    },
    openModal() {
      this.$refs.layerCreationModal.show();
    },
    uploadFile() {
      this.file = this.files[0];
      const fileName = this.file.name.toLowerCase();
      if (fileName.indexOf(".zip") !== -1) {
        let name = fileName.split(".");
        // Chrome adds c:\fakepath to the value - we need to remove it
        name = name[0].replace("c:\\fakepath\\", "");
        this.openModal();
      } else {
        this.uploadStatus = 'error';
        this.errorMessage = "Add shapefile as .zip file";
      }
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('is-dragover')) {
        event.currentTarget.classList.add('is-dragover');
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.remove('is-dragover');
    },
    drop(event) {
      event.preventDefault();
      this.files = event.dataTransfer.files;
      this.file = this.files[0];
      this.uploadFile(); // Trigger the upload event manually
      // Clean up
      event.currentTarget.classList.remove('is-dragover');
    },
    uploadStart() {
      this.errorMessage = '';
      this.uploadStatus = 'new';
      this.isUploading = true;
    },
    uploadEnd(status) {
      this.isUploading = false;
      this.uploadStatus = status;
    }
  },
};
</script>
<style lang="scss" scoped>
@import './src/assets/sass/pages/atlas/layer-uploader';
</style>
