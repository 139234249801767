import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import IdField from '@/shared/fields/id-field';
import BooleanField from '@/shared/fields/boolean-field';
import { ContainerLayerField } from '@/modules/atlas/fields/container-layer-field';
import { SimpleLayerField } from '@/modules/atlas/fields/simple-layer-field';
import { DecisionField } from '@/modules/administrator/fields/decision-field';

const label = (name) => i18n.t(`ATLAS.FIELDS.${name.toUpperCase()}`);

const fields = {
    id: new IdField('id', 'Id'),
    container_id: ContainerLayerField.relationToOne(
        'container_id',
        label('container_layer'),
        { autocompleteRequired: true },
        {},
        [],
        { asyncData: true }
    ),
    layer_id: SimpleLayerField.relationToOne(
        'layer_id',
        label('layer'),
        { autocompleteRequired: true },
        {},
        [{
            key: 'parent_id',
            name: 'container_id',
        }],
        { asyncData: true }
    ),
    decision_id: DecisionField.relationToOne(
        'decision_id',
        label('container_layer'),
        { autocompleteRequired: true },
        {},
        [],
        { asyncData: true }
    ),
    has_decision: new BooleanField(
        'has_decision',
        label('has_decision'),
    ),
};

export class LayerPublishModel extends GenericModel {
    static get fields() {
        return fields;
    }
}
