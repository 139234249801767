<template>
    <div>
        <st-modal
            ref="modal"
            hide-header-delimiter
            no-close-on-backdrop
            no-close-on-esc
            hide-footer
            hideable
            hide-header-close
            size="sm"
            :id="modalId"
        >
            <template #header>
                <div>
                    <p class="font-size-h6 font-weight-bold mb-0 pb-0">
                        {{ $t('ATLAS.PUBLISH_LAYER_MODAL.HEADER') }}
                    </p>
                    <div class="font-size-h6 text-dark-50 mt-2">{{ layerName }}</div>
                </div>
            </template>
            <template #body>
                <form
                    class="form"
                    novalidate="novalidate"
                    id="st_modal_publish_layer_form"
                >
                    <layer-publish-form
                        v-if="isLayerPublishModalVisible"
                        :id="modalId"
                        ref="publishLayerForm"
                        />
                </form>
                <div class="pt-6">
                    <div class="d-flex justify-content-between">
                    <st-button
                        size="large"
                        variant="link"
                        :callback="hide"
                        :disabled="isLoading"
                    >
                        {{ $t("GENERAL.BUTTON.CANCEL") }}
                    </st-button>
                    <st-button
                        size="large"
                        variant="primary"
                        :callback="doSubmit"
                        :spinner="isLoading"
                    >
                        <span>{{ $t("GENERAL.CHECK") }}</span>
                    </st-button>
                    </div>
                </div>
            </template>
        </st-modal>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { generateRandomId } from "@/core/helpers/globalMethods";
import { FormSchema } from "@/shared/form/form-schema";
import { AtlasLayerModel } from "@/modules/atlas/models/atlas-layer-model";
import LayerPublishForm from "@/modules/atlas/components/layer-manager/LayerPublishForm";
import Errors from '@/shared/error/errors';

const { fields } = AtlasLayerModel;

const formSchema = new FormSchema([fields.container_id]);

export default {
  inheritAttrs: false,
  name: "LayerPublishFormModal",
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  components: {
      LayerPublishForm,
  },
  data() {
    return {
      modalId: `st-modal-layer-publish-${generateRandomId()}`,
      rules: formSchema.rules(),
      model: null,
      fields,
      fv: null,
      polling: null,
      publishStatus: '',
    };
  },
  computed: {
    ...mapGetters({
      loading: "shared/loading",
      isLayerPublishModalVisible: 'atlas/layer-manager/isLayerPublishModalVisible',
      publishingLayer: 'atlas/layer-manager/publishingLayer',
    }),
    layerName() {
        return this.publishingLayer?.name || '';
    },
    isLoading() {
      return this.loading['atlas-layer-manager/compareLayers'];
    }
  },
  methods: {
    ...mapActions({
      hidePublishModal: "atlas/layer-manager/hidePublishModal",
      publishLayer: "atlas/layer-manager/publish",
      getLayerPublishStatus: "atlas/layer-manager/getLayerCreationStatus",
      addPublishedLayerToList: "atlas/layer-manager/addPublishedLayerToList",
      compareLayers: 'atlas/layer-manager/compareLayers',
      showCompareLayersModal: 'atlas/layer-manager/showCompareLayersModal',
    }),
    async doSubmit() {
      const formRef = this.$refs.publishLayerForm;
      const validate = await formRef.fv.validate();
      if (validate === "Valid") {
        const payload = {
            id: this.publishingLayer.id,
            name: this.publishingLayer.name,
            container_id: formRef.model.container_id,
            decision_id: formRef.model.decision_id,
            decision_key: formRef.model.decision_key,
        }
        try {
          const data = await this.compareLayers(payload);
          this.hide();
          this.showCompareLayersModal(
              {
                  comparedLayer: data,
                  sourceLayer: payload,
                  destinationLayerName: formRef.model.layer_name,
                }
            );
        } catch (error) {
            Errors.handle(error);
        } finally {
            this.hide();
        }
      }
    },
    show() {
      this.$refs["modal"].show();
    },
    hide() {
      this.$refs["modal"].hide();
      this.hidePublishModal();
    },
  },
};
</script>

