import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import StringField from '@/shared/fields/string-field';
import EnumField from '@/shared/fields/enum-field';
import IdField from '@/shared/fields/id-field';
import BooleanField from '@/shared/fields/boolean-field';
import { OrganizationField } from '@/modules/administrator/fields/organisations-field';
import { ContainerLayerField } from '@/modules/atlas/fields/container-layer-field';
import { SimpleLayerField } from '@/modules/atlas/fields/simple-layer-field';
import { TerritorialAdministrativeUnitField } from '@/shared/common-fields/territorial-administrative-unit-field';
import { DecisionField } from '@/modules/administrator/fields/decision-field';

const label = (name) => i18n.t(`ATLAS.FIELDS.${name.toUpperCase()}`);
const enumLabel = (name, value) => i18n.t(`ATLAS.ENUMS.${name.toUpperCase()}.${value.toUpperCase()}`);

const fields = {
    id: new IdField('id', 'Id'),
    name: new StringField('name', label('name'), {
        required: true,
    }),
    layer_type: new EnumField('layer_type', label('layer_type'), [
        { value: 'simple', text: enumLabel('layer_type', 'simple') },
        { value: 'container', text: enumLabel('layer_type', 'container') },
    ], {
        autocompleteRequired: true
    }),
    access_level: new EnumField('access_level', label('access_level'), [
        { value: 'private', text: enumLabel('access_level', 'private') },
        { value: 'public', text: enumLabel('access_level', 'public') },
        { value: 'protected', text: enumLabel('access_level', 'protected') }
    ], {
        autocompleteRequired: true
    }),
    ownership_type: new EnumField('ownership_type', label('ownership_type'), [
        { value: 'centralized', text: enumLabel('ownership_type', 'centralized') },
        { value: 'distributed', text: enumLabel('ownership_type', 'distributed') },
    ], {
        autocompleteRequired: true
    }),
    owner_org_id: OrganizationField.relationToOne(
        'owner_org_id',
        label('organization'),
        { autocompleteRequired: true },
        {},
        [],
        { asyncData: true }
    ),
    layer_name: new StringField('layer_name', label('name'), {
        required: true,
    }),
    container_id: ContainerLayerField.relationToOne(
        'container_id',
        label('container_layer'),
        { autocompleteRequired: true },
        {},
        [],
        { asyncData: true }
    ),
    layer_id: SimpleLayerField.relationToOne(
        'layer_id',
        label('layer'),
        { autocompleteRequired: true },
        {},
        [
            // {
            //     key: 'parent_id',
            //     name: 'container_id',
            // }
        ],
        { asyncData: true }
    ),
    ru_scope: new EnumField('ru_scope', label('ru_scope'), [
        { value: 'pug', text: enumLabel('ru_scope', 'PUG') },
        { value: 'puz', text: enumLabel('ru_scope', 'PUZ') },
        { value: 'pud', text: enumLabel('ru_scope', 'PUD') },
        { value: 'other', text: enumLabel('ru_scope', 'OTHER')}
    ], {
        autocompleteRequired: true
    }),
    uat_id: TerritorialAdministrativeUnitField.relationToOne(
        'uat_id',
        label('uat'),
        { autocompleteRequired: true, firstLetterFilter: true },
        {},
        [],
        { asyncData: true }
    ),
    use_as_template: new BooleanField(
        'use_as_template',
        label('use_as_template'),
    ),
    decision_id: DecisionField.relationToOne(
        'decision_id',
        label('decision'),
        { autocompleteRequired: true },
        {},
        [],
        { asyncData: true }
    ),
    has_decision: new BooleanField(
        'has_decision',
        label('has_decision'),
    ),
};

export class AtlasLayerModel extends GenericModel {
    static get fields() {
        return fields;
    }
}
