<template>
    <div class="mx-6">
        <p class="font-size-h6">
            {{headerText}}
        </p>
        <div class="row">
            <div class="col-12 col-md-6">
                <label> {{ fields.name.label }} * </label>
                <div class="form-group">
                    <st-input-text
                        v-model="model[fields.name.name]"
                        :ref="fields.name.name"
                        :name="fields.name.name"
                    />
                </div>
                <label>
                    {{ fields.uat_id.label }} *
                </label>
                <div class="form-group">
                    <st-autocomplete
                        v-model="model[fields.uat_id.name]"
                        :ref="fields.uat_id.name"
                        :name="fields.uat_id.name"
                        :options="fields.uat_id.options"
                        :fields="fields"
                        :model="model"
                        :formValidation="fv"
                    >
                    </st-autocomplete>
                </div>
                <label> {{ fields.ru_scope.label }} * </label>
                <div class="form-group">
                    <st-select
                        v-model="model[fields.ru_scope.name]"
                        :ref="fields.ru_scope.name"
                        :field="fields.ru_scope"
                        :formValidation="fv"
                    ></st-select>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <label> {{ fields.access_level.label }} * </label>
                <div class="form-group">
                    <st-select
                    v-model="model[fields.access_level.name]"
                    :ref="fields.access_level.name"
                    :field="fields.access_level"
                    :formValidation="fv"
                    ></st-select>
                </div>
                <label> {{ fields.owner_org_id.label }} * </label>
                <div class="form-group">
                    <st-select
                        v-model="model[fields.owner_org_id.name]"
                        :ref="fields.owner_org_id.name"
                        :field="fields.owner_org_id"
                        :formValidation="fv"
                        :disabled="!isContainerDistributed"
                    ></st-select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label> {{ fields.decision_id.label }} </label>
                    <st-select
                        v-model="model[fields.decision_id.name]"
                        :ref="fields.decision_id.name"
                        :field="fields.decision_id"
                        :formValidation="fv"
                    ></st-select>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { FormSchema } from "@/shared/form/form-schema";
import { AtlasLayerModel } from "@/modules/atlas/models/atlas-layer-model";
import { createFormValidation } from "@/shared/utils/create-form-validation";
import { mapGetters } from "vuex";
const { fields } = AtlasLayerModel;

const formSchema = new FormSchema([
  fields.name,
  fields.access_level,
  fields.owner_org_id,
  fields.ru_scope,
  fields.decision_id,
  fields.uat_id,
  fields.id,
]);

export default {
    name: "ContainerSimpleLayerForm",
    props: {
        id: {
            type: String,
            required: true,
        },
        selectedLayer: {
            type: Object
        },
        selectedComponent: {
            type: Object,
            default: () => ({}),
        },
        editMode: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            rules: formSchema.rules(),
            model: null,
            fields,
            fv: null,
        };
    },
    computed: {
        ...mapGetters({
            layer: 'atlas/layer-manager/editingLayer',
            accountDetails: "auth/accountDetails",
        }),
        isContainerDistributed () {
            return this.layer?.ownership_type === 'distributed';
        },
        headerText() {
            return this.editMode ? this.$t('ATLAS.LAYER_MANAGER.UPDATE_LAYER_TITLE') :
                                   this.$t('ATLAS.LAYER_MANAGER.ADD_LAYER_TITLE')
        }
    },
    watch: {
        'model.uat_id': {
            deep: true,
            immediate: true,
            handler(uat) {
                if (uat) {
                    this.model.uat_name = this.fields.uat_id.options.find(f => f.value === uat)?.text;
                }
            },
        },
        'model.owner_org_id': {
            deep: true,
            immediate: true,
            handler(org) {
                if (org) {
                    this.model.owner_org_name = this.fields.owner_org_id.options.find(f => f.value === org)?.text;
                }
            },
        },
        'model.decision_id': {
            deep: true,
            immediate: true,
            handler(decision) {
                if (decision) {
                    this.model.decision_key = this.fields.decision_id.options.find(f => f.id === decision)?.key || '';
                }
            },
        },
    },
    created() {
        this.model = formSchema.initialValues();
        this.model.owner_user_id = this.accountDetails.user_id;
        this.model.ru_scope = this.selectedLayer?.ru_scope ?? '';

        if (this.selectedLayer?.owner_org_id && !this.isContainerDistributed) this.model.owner_org_id = this.selectedLayer.owner_org_id;

        if (this.editMode) {
            const component = this.selectedLayer.components.find(f => f.id === this.selectedComponent.id);
            this.model = formSchema.initialValues(component);
        }
        this.fields.owner_org_id.label = this.$t('ATLAS.FIELDS.OWNER');
        this.model.container_id = this.selectedLayer.id;
    },
    mounted() {
        this.fv = createFormValidation(this.id, this.rules);
        // HCL field is not required (yet)
        this.fv.disableValidator(fields.decision_id.name);
    },
};
</script>
<style lang="">
</style>
