<template>
    <div class="layer-group">
        <div class="layer-group-header">
            <div class="layer-group-title">
                <i
                    :class="groupVisibilityClass"
                    @click="toggleGroupVisibility"></i>
                <span>{{ $t(`ATLAS.LAYER_MANAGER.${id.toUpperCase()}_LABEL`) }}</span>
            </div>
        </div>

        <div class="layer-group-body" v-cloak v-show="isVisible">
            <template v-for="layer in layers">
                <layer-item :layer="layer" :key="layer.id" :isContainerOpen.sync="isContainerOpen" :group="id"/>
                <div v-if="layer.components && layer.components.length"
                        class="container-layer-components"
                        :key="`${layer.id}_comp`">
                    <template v-for="layerComponent in layer.components">
                        <layer-item
                            v-show="openContainers.includes(layerComponent.container_id)"
                            :layer="layerComponent"
                            :key="layerComponent.id"
                            :group="id"
                            :container="layer"
                            :layerSelected.sync="layerSelected"
                        />
                    </template>
                </div>
            </template>
            <div v-if="!layers.length" class="ml-6">{{ $t('ATLAS.LAYER_MANAGER.EMPTY_LAYER_TEXT') }}</div>
        </div>
    </div>
</template>
<script>
import LayerItem from "@/modules/atlas/components/layer-manager/LayerItem";
import { mapGetters } from 'vuex';
export default {
  name: "LayerGroup",
  components: {
      LayerItem,
  },
  props: {
      id: {
          type: String,
          required: true,
      },
      layers: {
          type: Array,
          default: () => [],
      }
  },
  data() {
      return {
          isVisible: true,
          isContainerOpen: {},
          openContainers: [],
          layerSelected: null,
      }
  },
  computed: {
      ...mapGetters({
            selectedLayers: 'atlas/layer-manager/selectedLayers',
        }),
    groupVisibilityClass() {
        return this.isVisible ? 'fas fa-caret-down' : 'fas fa-caret-right';
    },
  },
  watch: {
      isContainerOpen(value) {
        if(value.container) {
            this.openContainers.push(value.id);
        } else {
            this.openContainers = this.openContainers.filter(e => e !== value.id);
        }
      }
  },
  methods: {
      toggleGroupVisibility() {
          this.isVisible = !this.isVisible;
      }
  },
  mounted() {
    // expand folder(s) (container) if at least layer(s) is/are visible
    this.layers.forEach((layer) => {
        if (layer.components && layer.components.length) {
            const components = layer.components;
            const visibleLayers = Object.keys(this.selectedLayers).filter((el) => this.selectedLayers[el].visible);
            const isContainerOpen = components.some(el => visibleLayers.includes(el.gis_layer_id));
            if (isContainerOpen) {
                this.openContainers.push(layer.id)
            }
        }
    })
  },

}
</script>
<style lang="scss" scoped>
.container-layer-components {
    padding-left: 1rem;
}
</style>
