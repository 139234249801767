import RelationToOneField from '@/shared/fields/relation-to-one-field';
import ApiService from '@/shared/services/api.service';

export class OrganizationField {
    static relationToOne(name, label, rules, filterParams, children, options) {
        return new RelationToOneField(
            name,
            label,
            () => {
                return new Promise((resolve, reject) => {
                    ApiService.get('/organisations?limit=100')
                    .then(({data}) => resolve(data.data))
                    .catch((error) => reject(error));
                })
            },
            (record) => {
                if (!record) {
                    return null;
                }
                return {
                    value: record.id,
                    text: record.name,
                };
            },
            rules,
            filterParams,
            children,
            options,
        );
    }
}
