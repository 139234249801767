<template>
  <div>
    <div class="row">
      <div class="col-12">
        <label> {{ fields.container_id.label }} * </label>
        <div class="form-group">
          <st-select
            v-model="model[fields.container_id.name]"
            :ref="fields.container_id.name"
            :field="fields.container_id"
            :formValidation="fv"
          ></st-select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label> {{ fields.layer_id.label }} * </label>
        <div class="form-group">
          <st-select
            v-model="model[fields.layer_id.name]"
            :ref="fields.layer_id.name"
            :field="fields.layer_id"
            :model="model"
            :formValidation="fv"
          ></st-select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label> {{ fields.decision_id.label }} * </label>
        <div class="form-group">
          <b-form-checkbox
            v-model="model[fields.has_decision.name]"
            :ref="fields.has_decision.name"
            :name="fields.has_decision.name"
            size="lg"
          >
            {{ fields.has_decision.label }}
          </b-form-checkbox>
          <st-select
            v-if="!model[fields.has_decision.name]"
            v-model="model[fields.decision_id.name]"
            :ref="fields.decision_id.name"
            :field="fields.decision_id"
            :formValidation="fv"
            custom-class="mt-4"
          ></st-select>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { createFormValidation } from "@/shared/utils/create-form-validation";
import { FormSchema } from "@/shared/form/form-schema";
import { LayerPublishModel } from "@/modules/atlas/models/layer-publish-model";

const { fields } = LayerPublishModel;

const formSchema = new FormSchema([
  fields.container_id,
  fields.layer_id,
  fields.has_decision,
  fields.decision_id,
]);

export default {
  inheritAttrs: false,
  name: "LayerPublishForm",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      rules: formSchema.rules(),
      model: {
        has_decision: false,
      },
      fields,
      fv: null,
    };
  },
  watch: {
    "model.decision_id"(newValue) {
      const decisionKey =
        fields.decision_id?.options.find((el) => el.id === newValue)?.key || "";
      this.model.decision_key = decisionKey;
    },
     "model.layer_id"(newValue) {
        if (newValue) {
            const layerName = fields.layer_id?.options.find((el) => el.id === newValue.value)?.text || '';
            this.model.layer_name = layerName;
        }
    },
  },
  created() {
    this.model = formSchema.initialValues({});
  },
  mounted() {
    this.fv = createFormValidation(this.id, this.rules);
  },
};
</script>

